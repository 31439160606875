<template>
  <div class="select-form">
    <div class="select-form__body">
      <div class="select-form__title">
        Калькулятор расчета Беталок<sup>®</sup> ЗОК с другими лекарственными
        средствами
      </div>
      <div class="select-form__inputs">
        <Select
          label="Препарат №1"
          :placeholder="'Метопролола сукцинат'"
          :items="[{ label: '1', value: '1' }]"
          :multiple="false"
          :disabled="true"
          v-model="drug"
          :searchable="false"
        >
          <template #list-header>
            <div class="select-form__select-header">Выберите симптом</div>
          </template>
        </Select>
        <Select
          label="Препарат №2"
          placeholder="Выберите препарат"
          :items="drugs"
          :multiple="false"
          :searchable="false"
          v-model="drug"
          @optionTriggered="clearResults"
        >
          <template #list-header>
            <div class="select-form__select-header">Выберите препарат</div>
          </template>
        </Select>
      </div>
      <div
        class="select-form__button button button_pink d-inline-flex"
        @click="showResults"
      >
        Рассчитать
      </div>
    </div>
    <div class="select-form__result" v-if="resultsShow">
      <div class="select-form__result-warning" v-if="!result">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0C4.47734 0 0 4.47734 0 10C0 15.5227 4.47734 20 10 20C15.5227 20 20 15.5227 20 10C20 4.47734 15.5227 0 10 0ZM11.3996 14.0691C11.3996 14.4403 11.2522 14.7963 10.9897 15.0588C10.7272 15.3213 10.3712 15.4688 10 15.4688C9.6288 15.4688 9.2728 15.3213 9.01033 15.0588C8.74785 14.7963 8.60039 14.4403 8.60039 14.0691C8.60039 13.6979 8.74785 13.3419 9.01033 13.0795C9.2728 12.817 9.6288 12.6695 10 12.6695C10.3712 12.6695 10.7272 12.817 10.9897 13.0795C11.2522 13.3419 11.3996 13.6979 11.3996 14.0691ZM11.5855 5.98828L11.4008 10.6539C11.3864 11.0158 11.2325 11.358 10.9713 11.6089C10.7102 11.8598 10.3621 12 10 12C9.63786 12 9.28977 11.8598 9.02866 11.6089C8.76754 11.358 8.61365 11.0158 8.59922 10.6539L8.41445 5.98828C8.40704 5.79969 8.43778 5.61154 8.50483 5.43512C8.57189 5.2587 8.67387 5.09763 8.80468 4.96157C8.93548 4.82552 9.09241 4.71727 9.26606 4.64333C9.43971 4.56938 9.6265 4.53126 9.81523 4.53125H10.1848C10.3735 4.53126 10.5603 4.56938 10.7339 4.64333C10.9076 4.71727 11.0645 4.82552 11.1953 4.96157C11.3261 5.09763 11.4281 5.2587 11.4952 5.43512C11.5622 5.61154 11.593 5.79969 11.5855 5.98828Z"
            fill="white"
          />
        </svg>
        Заполните все поля
      </div>
      <div class="select-form__result-text" v-else>
        <div
          class="select-form__result-title"
          v-html="results[parseInt(result) - 1]"
        ></div>
        <div class="select-form__result-description">
          Совместное применение Беталок<sup>®</sup> ЗОК (Метопролола сукцинат) с
          данными препаратами с осторожностью, может потребоваться коррекция
          дозы
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/pageComponents/betalockLanding/Select.vue";
export default {
  name: "SelectForm",
  data: () => ({
    drug: [],
    resultsShow: false,
    result: null,
  }),
  components: {
    Select,
  },
  computed: {
    drugs() {
      return [
        {
          label: "Амиодарон",
          value: 1,
        },
        {
          label: "Антиаритмические средства I класса",
          value: 2,
        },
        {
          label: "НПВП",
          value: 3,
        },
        {
          label: "Дифенгидрамин",
          value: 4,
        },
        {
          label: "Дилтиазем",
          value: 5,
        },
        {
          label: "Эпинефрин (адреналин)",
          value: 6,
        },
        {
          label: "Фенилпропаноламин (норэфедрин)",
          value: 7,
        },
        {
          label: "Хинидин",
          value: 8,
        },
        {
          label: "Клонидин",
          value: 9,
        },
        {
          label: "Рифампицин",
          value: 10,
        },
        {
          label: "Гипогликемические препараты",
          value: 11,
        },
        {
          label: "Сердечные гликозиды",
          value: 12,
        },
      ];
    },
    results() {
      return [
        "Риск выраженной синусовой брадикардии",
        "Усиление отрицательного инотропного эффекта → гемодинамические побочные эффекты у пациентов со сниженной фракцией выброса <br><br>Опасно совместное применение о пациентов с СССУ и нарушением АВ-проводимости",
        "Ослабление гипотензивного эффекта бета-адреноблокаторов",
        "Снижение клиренса метопролола с усилением его действия",
        "Риск выраженной брадикардии, нарушения АВ-проводимости",
        "Риск артериальной гипертензии и брадикардии",
        "Парадоксальная артериальная гипертензия",
        "Увеличение плазменной концентрации метопролола и усиление бета-адреноблокады",
        "При отмене клонидина – выраженная артериальная гипертензия ",
        "Уменьшение плазменной концентрации метопролола",
        "Усиление гипогликемического эффекта",
        "Удлинение АВ-проводимости, брадикардия",
      ];
    },
  },
  methods: {
    showResults() {
      this.$set(this, "result", this.drug[0]);
      this.resultsShow = true;
    },
    clearResults() {
      this.resultsShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-form {
  max-width: 900px;
  width: 100%;
  margin: 0 auto 0px;
  border: 1px solid #d2d2d2;
  border-radius: 32px;
  box-shadow: 0px 40px 50px -30px #00000059;
  background-color: #f8f8f8;

  @media screen and (max-width: 767px) {
    border-radius: 20px;
  }

  &__select-header {
    margin-bottom: 16px;
    padding: 10px 16px;
    border-radius: 8px;
    background: #f8f8f8;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #3c4242;
  }

  &__body {
    padding: 32px;
    max-width: 90%;

    @media screen and (max-width: 1024px) {
      max-width: none;
      padding: 30px;
    }

    @media screen and (max-width: 767px) {
      padding: 20px;
    }
  }

  &__title {
    margin-bottom: 40px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #830051;

    @media screen and (max-width: 1024px) {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 20px;
    }
  }

  &__result {
    color: #fff;
    padding: 32px;
    background: #830051;
    border-top: 1px solid #d2d2d2;
    border-radius: 0 0 32px 32px;

    @media screen and (max-width: 1024px) {
      padding: 18px 30px;
    }

    @media screen and (max-width: 767px) {
      padding: 15px 20px;
      border-radius: 0 0 20px 20px;
    }

    &-warning {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #fff;

      & > svg {
        margin-right: 8px;
        flex-shrink: 0;
      }
    }

    &-title {
      margin-bottom: 18px;
      font-family: "Murecho", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      color: #fff;
      margin-bottom: 18px;

      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }

  &__inputs {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 280px 350px;
    column-gap: 40px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 100%;
      row-gap: 20px;
    }
  }

  sup {
    vertical-align: super;
    font-family: inherit;
    font-style: normal;
    font-weight: inherit;
    font-size: smaller;
    line-height: 1;
    color: currentColor;
    white-space: nowrap;
  }
}
</style>